import Vue from "vue";

/*TITLE*/
document.title = "Miraflores | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Miraflores";
Vue.prototype.$subtitle = "111 Viviendas de 2, 3 y 4 dormitorios";

/*INTRO*/
Vue.prototype.$promoter = "Miraflores";
Vue.prototype.$introSubtitle = "111 Viviendas de 2, 3 y 4 dormitorios";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-tortona-1l-piazza--20230127090133.jpg";
Vue.prototype.$image_kitchen = "cocinat3-tortona-1l-piazzablanco-nubolsilestone-blanco-norte_web--20230127090144.jpg";
Vue.prototype.$image_bath1 = "banyo1-carrara-blanco-pulidocarrara-blanco-pulidomediterranea-calpe-seagreen_web--20230127090156.jpg";
Vue.prototype.$image_bath2 = "banyo2-carrara-blanco-pulidocarrara-blanco-pulidomediterranea-calpe-seagreen_web--20230127090102.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-tortona-1l-piazza--20230127090133.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocinat3-tortona-1l-piazzablanco-nubolsilestone-blanco-norte_web--20230127090144.jpg",
  },
  {
    src: "cocinat1-tortona-1l-piazzablanco-nubolsilestone-blanco-norte_web--20230127090148.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1-carrara-blanco-pulidocarrara-blanco-pulidomediterranea-calpe-seagreen_web--20230127090156.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2-carrara-blanco-pulidocarrara-blanco-pulidomediterranea-calpe-seagreen_web--20230127090102.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/RESIDENCE_MIRAFLORES/";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=parque%20urbano%20de%20miraflores%20algés%20lisboa%20portugal",
    text: "Parque Urbano de Miraflores, Algés, Lisboa, Portugal",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=rua%20quinta%20das%20romeiras%20miraflores%201495%20236%20algés%20portugal",
    text: "Rua Quinta das Romeiras Miraflores, 1495-236 Algés, Portugal",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+34913697450",
    text: "+34913697450",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@gestilar.com",
    text: "info@gestilar.com",
  },
];
